html,
body {
  margin: 0;
  padding: 0;
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #333;
  background-color: linear-gradient(360deg, #dee1e1 10%, #f4f4f4 160%);
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}

input[type='file'] {
  width: 100%;
}

#root {
  height: 100%;
}
