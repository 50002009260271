@keyframes highlight-text-animation {
  0% {
    color: rgba(0, 0, 0, 0.87);
  }
  50% {
    color: rgba(0, 0, 0, 0.2);
  }
  100% {
    color: rgba(0, 0, 0, 0.87);
  }
}

.text {
  color: rgba(0, 0, 0, 0.87);
  animation: highlight-text-animation 0.3s linear;
}
